<div class="wrapper">
    <ngx-spinner bdColor='rgba(0, 0, 0, 0.8)' size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
    <!-- Navbar -->
    <app-navbar role="navigation"  [horizontal]="horizontal" *ngIf="currentUser===true && pop===false  && horizontal==true"></app-navbar>
    <!-- Page Content  -->   
    <div id="content">
        <div class="routlet">
            <router-outlet></router-outlet>
        </div>
        <div *ngIf="currentUser===true" class="routlet">
            <app-tabs-component></app-tabs-component>
        </div>
    </div>
</div>
<div class="overlay"></div>