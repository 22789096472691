import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Valores en español para el datepicker
 */
const I18N_VALUES = {
    es: {
        weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    }
};

/**
 * Internacionalización del datepicker
 */
@Injectable()
export class JnumDatePickerI18n extends NgbDatepickerI18n {
    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return "";
    }
    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES['es'].weekdays[weekday - 1];
    }
    getMonthShortName(month: number, year?: number): string {
        return I18N_VALUES['es'].months[month - 1];
    }
    getMonthFullName(month: number, year?: number): string {
        return this.getMonthShortName(month);
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

}
