import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneratedAppRoutingModule, generatedroutes } from './generated-app-routing.module';
import { JnumPreloading } from './jnum-core/preload/jnum-preloading';


const routes: Routes = [
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot([...routes,...generatedroutes], {useHash: true, onSameUrlNavigation: 'reload'})],
  providers: [JnumPreloading]
})
/**
 * Módulo principal de routing
 */
export class AppRoutingModule extends GeneratedAppRoutingModule {}