import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from "@angular/router";

/**
 * Manejador de outlets
 */
export class OutletHandler {
  outletname: string = "";
  handlers = new Map<string, DetachedRouteHandle>();
}

/**
 * Estrategia de reutilización de rutas
 */
@Injectable()
export class JnumStateStrategy implements RouteReuseStrategy {
  private outletHandlers: OutletHandler[] = [];
  private isRouteRemove: boolean = false;
  private useRoute = true;
  private activeOutlet: string;
  private handlers = new Map<string, DetachedRouteHandle>();

  constructor() {
    this.activeOutlet = "primary";
    this.outletHandlers.push(this.createNewOutletHandler(this.activeOutlet));
  }

  /**
   * Indica si debemos reusar una ruta.
   *
   * @param  {boolean} useRoute
   */
  reuseRoute(useRoute: boolean) {
    this.useRoute = useRoute;
  }

  /**
   * Crea un nuevo manejador de rutas para un outlet.
   *
   * @param  {any} outletname
   * @returns OutletHandler
   */
  createNewOutletHandler(outletname: any): OutletHandler {
    let newoulethandler = new OutletHandler();
    newoulethandler.outletname = outletname;
    return newoulethandler;
  }
  /**
   * Establece el outlet activo.
   *
   * @param  {string} outletname
   */
  setActiveOutlet(outletname: string) {
    this.activeOutlet = outletname;
    let handle = this.outletHandlers.find((x) => x.outletname == outletname);
    if (!handle) {
      handle = this.createNewOutletHandler(outletname);
      this.outletHandlers.push(handle);
    }
    this.handlers = handle.handlers;
  }

  /**
   * Getter activeOutlet
   *
   * @returns string
   */
  getActiveOutlet(): string {
    return this.activeOutlet;
  }

  /**
   * Se ejecuta cada vez que cambia una ruta, determina si se reutilizará la ruta, si el método retorna TRUE
   * los demas métodos no seran ejecutados, pero si retorna FALSE los demas métodos seran ejecutados.
   */
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    let reUseUrl = false;

    if (future.routeConfig) {
      if (future.routeConfig.data) {
        reUseUrl = future.routeConfig.data["reuse"];
      }
    }
    return reUseUrl && this.useRoute;
  }

  /**
   * Cuando se sale de un ruta se llama a este método el cual si devuelve TRUE se ejecutara el método store()
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    let shouldReuse = false;
    if (route && route.routeConfig) {
      if (route.routeConfig.data) {
        route.routeConfig.data["reuse"]
          ? (shouldReuse = true)
          : (shouldReuse = false);
      }
    }

    return shouldReuse;
  }

  /**
   *   En este método realizamos el guardado de las instancias de las rutas que queremos reutilizar
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (handle && !this.isRouteRemove) {
      if (route && route.routeConfig && route.routeConfig.path) {
        this.handlers.set(
          this.replaceParams(route.routeConfig.path, route.params),
          handle
        );
      }
    }
    this.isRouteRemove = false;
  }

  /**
   * Si ingresamos a una ruta este método se ejecutará y si devuelve TRUE se ejecutara el método retrieve()
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route && route.routeConfig && route.routeConfig.path) {
      return (
        route &&
        route.routeConfig &&
        !!this.handlers.get(
          this.replaceParams(route.routeConfig.path, route.params)
        ) &&
        this.useRoute
      );
    }
    return false;
  }

  /**
   *  Este método retornaria la instancia de la ruta guardada anteriormente, si la instancia no fue guardada retornara un valor NULL
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    let handle = null;
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return handle;
    }
    if (route.routeConfig.path) {
      handle =
        this.handlers.get(
          this.replaceParams(route.routeConfig.path, route.params)
        ) ?? null;
    }
    return handle;
  }
  /**
   * Reemplaza los parametros de la url.
   *
   * @param  {string} path
   * @param  {any={}} params
   */
  private replaceParams(path: string, params: any = {}) {
    const keys = Object.keys(params);

    keys.forEach((key: string) => {
      const reg = new RegExp(`:${key}`);
      path = path.replace(reg, params[key]);
    });

    return path;
  }

  /**
   * Elimina una ruta del manejador.
   *
   * @param  {string} route
   */
  public removeRoute(route: string) {
    let outlethandlers = this.outletHandlers.find(
      (x) => x.outletname == this.activeOutlet
    );
    if (outlethandlers) {
      this.isRouteRemove = true;
      for (let key of outlethandlers.handlers.keys()) {
        if (route.indexOf(key) != -1) {
          this.handlers.delete(key);
        }
      }
    }
  }
  /**
   * Elimina un manejador de outlet.
   *
   * @param  {string} outletname
   */
  public removeOutlethandlers(outletname: string) {
    let outlethandlers = this.outletHandlers.find(
      (x) => x.outletname == outletname
    );
    if (outlethandlers) {
      this.isRouteRemove = true;
      for (let key of outlethandlers.handlers.keys()) {
        this.handlers.delete(key);
      }
    }
  }

  /**
   * Elimina todos los manejadores
   */
  removeAllRoute() {
    this.outletHandlers = [];
  }
}
