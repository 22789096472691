import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

/**
 * Loader de traducción
 */
class JnumTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public resources: { prefix: string; suffix: string }[] = [
      {
        prefix: "/assets/i18n/",
        suffix: ".json",
      },
    ]
  ) {}

  /**
   * Gets the translations from the server
   * @param lang
   * @returns {any}
   */
  public getTranslation(lang: string): any {
    return forkJoin(
      this.resources.map((config) => {
        return this.http.get(`${config.prefix}${lang}${config.suffix}`);
      })
    ).pipe(
      map((response) => {
        return response.reduce((a, b) => {
          return Object.assign(a, b);
        });
      })
    );
  }
}

/**
 * Carga los ficheros de traducción
 * @param http
 * @returns
 */
export function JnumTranslateLoaderFactory(http: HttpClient) {
  return new JnumTranslateLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
    { prefix: "./assets/i18n/custom-", suffix: ".json" },
  ]);
}
