import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { TypeMessage } from "../enum/type-message.enum";
import { ToasterService } from "../services/toaster.service";
import { ErrorService } from "./error.service";
import { LoggingService } from "./logging.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse): void {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const toasterService = this.injector.get(ToasterService);
    const spinner = this.injector.get(NgxSpinnerService);
    const zone = this.injector.get(NgZone);
    let message: any;
    let stackTrace: any;

    spinner.hide();

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
      zone.run(() =>
        toasterService.showToaster(TypeMessage.ERROR_MESSAGE, message)
      );
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
    }

    // Always log errors
    logger.logError(message, stackTrace);

    //console.error(error);
  }
}
