import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  private _genericError!: string;

  /**
   * Getter genericError
   * @return {string}
   */
  public get genericError(): string {
    return this._genericError;
  }

  /**
   * Setter genericError
   * @param {string} value
   */
  public set genericError(value: string) {
    this._genericError = value;
  }

  constructor(private translate: TranslateService) {
    this.genericError = this.translate.instant("toaster.genericerror");
  }

  getServerMessageByStatus(error: HttpErrorResponse): string {
    let errorMessage = this.translate.instant("toaster.genericerror");
    if (error.status) {
      switch (error.status) {
        case 403:
        case 401:
          errorMessage = "No tiene permisos para acceder a ese recurso";
          break;
        default:
          errorMessage = this.translate.instant("toaster.genericerror");
          break;
      }
    }
    return errorMessage;
  }

  /**
   * @param  {Error} error
   * @returns string
   */
  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return "No Internet Connection";
    }
    return error.message ? error.message : error.toString();
  }

  /**
   * @param  {Error} error
   * @returns string
   */
  getClientStack(error: Error): string | undefined {
    return error.stack;
  }

  /**
   * @param  {HttpErrorResponse} error
   * @returns string
   */
  getServerMessage(error: HttpErrorResponse): string {
    const message = error.error ? error.error.message : error.message;
    return message ? message : this.genericError;
  }

  /**
   * @param  {HttpErrorResponse} error
   * @returns string
   */
  getServerStack(error: HttpErrorResponse): string {
    return error.error;
  }
  /**
   * @param  {HttpErrorResponse} error
   */
  goToHome(error: HttpErrorResponse) {
    return (
      error.status &&
      (error.status == HttpStatusCode.Forbidden ||
        error.status == HttpStatusCode.Unauthorized)
    );
  }
}
