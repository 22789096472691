import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs/internal/Subscription";
import { MODAL_OPTIONS } from "src/app/jnum-core/constants/jnum-constants";
import { JnumStateStrategy } from "src/app/jnum-core/routing/jnumstatestrategy.component";
import { AuthenticationService } from "src/app/jnum-core/services/authentication.service";
import { IconService } from "src/app/jnum-core/services/icons.service";
import { NavigationService } from "src/app/jnum-core/services/navigation.service";
import { TabService } from "src/app/jnum-core/services/tab.service";
import { ToasterService } from "src/app/jnum-core/services/toaster.service";
import { UtilService } from "src/app/jnum-core/services/util.service";
import { environment } from "src/environments/environment";
import { NavbarVerticalComponent } from "./jnum-core/navbar/navbar-vertical.component";
import { DialogPasswordComponent } from "./jnum-core/password/dialog-password.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("navbarvertical", { static: false })
  navbarvertical!: NavbarVerticalComponent;
  @ViewChild(RouterOutlet) outlet!: RouterOutlet;
  openTabEventSubscription!: Subscription;
  pop = false;
  loading = false;
  opened: boolean = false;
  horizontal = true;
  mode = "side";
  currentUser!: boolean;
  tabs = [];
  activeTabOulet: string = "primary";
  jnumstatestrategy: JnumStateStrategy;
  password!: string;
  newpassword!: string;
  confirmpassword!: string;

  constructor(
    protected toasterService: ToasterService,
    protected navigationService: NavigationService,
    protected translate: TranslateService,
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected iconService: IconService,
    protected tabService: TabService,
    protected utilService: UtilService,
    protected modalService: NgbModal
  ) {
    this.authenticationService.currentUser.subscribe((x) => {
      setTimeout(() => {
        this.currentUser = x;
      });
    });
    navigationService.loadRouting();
    translate.setDefaultLang(environment.defaultLocale);
    if (!this.authenticationService.isLoggedIn) {
      router.navigateByUrl("/login");
    }
    this.jnumstatestrategy = router.routeReuseStrategy as JnumStateStrategy;
  } 

  ngOnInit(): void {
    this.iconService.registerIcons();
  }

  ngOnDestroy(): void {
    this.router
      .navigate([{ outlets: { [this.activeTabOulet]: null } }])
      .then(() => {
        this.tabService.clearTabs();
        this.jnumstatestrategy.removeAllRoute();
      });
  }

  closeSession(): void {
    this.router
      .navigate([{ outlets: { [this.utilService.activeOutlet]: null } }])
      .then(() => {
        this.authenticationService.logout();
        this.router.navigate(["/login"]);
        this.utilService.activeOutlet = "";
      });
  }

  changePassword(): void {
    const dialogRef = this.modalService.open(
      DialogPasswordComponent,
      MODAL_OPTIONS
    );
    let data = {
      password: this.password,
      newpassword: this.newpassword,
      passwordconfirm: this.confirmpassword,
    };
    dialogRef.componentInstance.data = data;
  }
}
