import { Routes } from '@angular/router';
import { HomeComponent } from 'src/app/jnum-core/home/home.component';
import { LoginComponent } from 'src/app/jnum-core/login/login.component';
import { AuthGuard } from 'src/app/jnum-core/util/guard/auth-guard';

export const generatedroutes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] }, 
  { path: 'login', component: LoginComponent },
     {
    path: "comun",
    loadChildren: () =>
      import("src/app/customized/comun/comun.module").then((m) => m.ComunModule),
    canActivate: [AuthGuard],
    },              
   {
    path: "dgc",
    loadChildren: () =>
      import("src/app/customized/dgc/dgc.module").then((m) => m.DgcModule),
    canActivate: [AuthGuard],
    },              
   {
    path: "ine",
    loadChildren: () =>
      import("src/app/customized/ine/ine.module").then((m) => m.IneModule),
    canActivate: [AuthGuard],
    },              
   {
    path: "informes",
    loadChildren: () =>
      import("src/app/customized/informes/informes.module").then((m) => m.InformesModule),
    canActivate: [AuthGuard],
    },              
   {
    path: "seguridad",
    loadChildren: () =>
      import("src/app/customized/seguridad/seguridad.module").then((m) => m.SeguridadModule),
    canActivate: [AuthGuard],
    },              
   {
    path: "tareas",
    loadChildren: () =>
      import("src/app/customized/tareas/tareas.module").then((m) => m.TareasModule),
    canActivate: [AuthGuard],
    },              
   {
    path: "utilidades",
    loadChildren: () =>
      import("src/app/customized/utilidades/utilidades.module").then((m) => m.UtilidadesModule),
    canActivate: [AuthGuard],
    },              
];

/**
 * Módulo generado de routing
 */
export class GeneratedAppRoutingModule {}