import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { NgbDateAdapter, NgbDatepickerConfig, NgbDatepickerI18n, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskDirective, provideEnvironmentNgxMask } from "ngx-mask";
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { JnumDateAdapter } from 'src/app/jnum-core/adapters/datepicker-adapter';
import { JnumDatePickerI18n } from 'src/app/jnum-core/adapters/datepickeri18n-adapter';
import { JnumCoreModule } from 'src/app/jnum-core/jnum-core.module';
import { JnumStateStrategy } from 'src/app/jnum-core/routing/jnumstatestrategy.component';
import { AuthenticationService } from 'src/app/jnum-core/services/authentication.service';
import { AuthorizationService } from 'src/app/jnum-core/services/authorization.service';
import { NavigationService } from 'src/app/jnum-core/services/navigation.service';
import { RegisterService } from 'src/app/jnum-core/services/register.service';
import { ToasterService } from "src/app/jnum-core/services/toaster.service";
import { UtilService } from 'src/app/jnum-core/services/util.service';
import { ValidationFormService } from 'src/app/jnum-core/services/validation-form.service';
import { AuthGuard } from 'src/app/jnum-core/util/guard/auth-guard';
import { JnumDatePickerConfig } from 'src/app/jnum-core/adapters/datepicker-config';
import { JnumTimePickerAdapter } from 'src/app/jnum-core/adapters/timepicker-adapter';
import { GlobalErrorHandler } from 'src/app/jnum-core/errors/global-error-handler';
import { JnumTranslateLoaderFactory } from 'src/app/jnum-core/translate/jnum-translate-loader';
registerLocaleData(localeEs, 'es');

@NgModule( {
    declarations: [
        AppComponent,       
    ],
    imports: [
        FormsModule,
        JnumCoreModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxMaskDirective,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: JnumTranslateLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AuthGuard,
        AuthenticationService,
        AuthorizationService,
        RegisterService,
        ValidationFormService,
        NavigationService,
        ToasterService,
        UtilService,
        { provide: RouteReuseStrategy, useClass: JnumStateStrategy },
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: NgbDatepickerI18n, useClass: JnumDatePickerI18n },
        { provide: NgbDateAdapter, useClass: JnumDateAdapter },
        { provide: NgbTimeAdapter, useClass: JnumTimePickerAdapter },
        { provide: NgbDatepickerConfig, useClass: JnumDatePickerConfig },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideEnvironmentNgxMask(),
    ],
    bootstrap: [AppComponent]
} )
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http,'./assets/i18n/');
}
