import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';


const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

/**
 * Adapter del TimePicker
 */
@Injectable()
export class JnumTimePickerAdapter extends NgbTimeAdapter<string> {
  /**
   * @param  {string|null} value
   * @returns NgbTimeStruct
   */
  fromModel(value: string| null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.match(/.{2}/g);
    if (!split) {
      return null;
    }    
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }
  /**
   * @param  {NgbTimeStruct|null} time
   * @returns string
   */
  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${pad(time.hour)}${pad(time.minute)}` : null;
  }
}